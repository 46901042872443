@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}

.no-scroll-display::-webkit-scrollbar {
  display: none;
  width: 0%;
}